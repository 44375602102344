import Box from "@mui/material/Box";
import { useRecoilValue } from "recoil";
import { MRT_ColumnDef } from "material-react-table";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import CommonTable from "../../common/table/CommonTable";
import useBoardListQuery from "../../../hook/boardManagement/useBoardListQuery";
import { NoticeType, QuestionType } from "../../../types/boardManagementTypes";
import { MemberType } from "../../../types/memberManagementTypes";
import QuestionManagementFilter from "./QuestionManagementFilter";
import { questionManagementFilterAtom } from "../../../store/memberManagement/questionMnagementFilterStore";
import { translateValue } from "../../../util/util";
import { memberTypeConfig } from "../../../config/config";

const QuestionManagementList = () => {
  const filterParams = useRecoilValue(questionManagementFilterAtom);
  const { data: noticeList, refetch } = useBoardListQuery({
    params: filterParams,
    endPoint: "qna",
  });

  const noticeColumns: MRT_ColumnDef<
    MemberType | NoticeType | QuestionType,
    unknown
  >[] = [
    {
      accessorKey: "memberType",
      header: "회원 유형",
      Cell: ({ row }: { row: any }) => {
        return translateValue(row.original.memberType, memberTypeConfig);
      },
    },
    {
      accessorKey: "boardContent",
      header: "문의내용",
      Cell: ({ row }: { row: any }) => {
        return (
          <Link
            to="/question-management/question-form"
            state={row.original.id}
            style={{
              textDecoration: "underline",
              color: "black",
            }}
          >
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "200px",
              }}
            >
              {row.original.boardContent}
            </div>
          </Link>
        );
      },
    },
    {
      accessorKey: "createdAt",
      header: "문의 등록일",
      Cell: ({ row }: { row: any }) => {
        const date = row.original.createdAt;
        return date ? moment(row.original.createdAt).format("YYYY.MM.DD") : "";
      },
    },
    { accessorKey: "createdMemberName", header: "문의 등록자" },
    {
      accessorKey: "replyFlag",
      header: "답변 여부",
      Cell: ({ row }: { row: any }) => {
        return row.original.replyFlag;
      },
    },
    {
      accessorKey: "replyDateTime",
      header: "답변 등록일",
      Cell: ({ row }: { row: any }) => {
        const date = row.original.replyDatetime;
        return date
          ? moment(row.original.replyDatetime).format("YYYY.MM.DD")
          : "";
      },
    },
    { accessorKey: "replyMemberName", header: "답변 등록자" },
  ];

  return (
    <Box sx={{ bgcolor: "background.paper" }}>
      <div style={{ padding: "18px" }}>
        <QuestionManagementFilter refetch={refetch} />
      </div>

      <div
        style={{
          paddingLeft: "2rem",
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <h5 style={{ color: "black" }}>
          <strong>
            답변 대기 :{" "}
            <span style={{ color: "#0288d1" }}> {noticeList?.reply_count}</span>
            건
          </strong>
        </h5>
      </div>

      <Box sx={{ p: 3 }}>
        <CommonTable columns={noticeColumns} data={noticeList} />
      </Box>
    </Box>
  );
};

export default QuestionManagementList;
