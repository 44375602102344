import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from "material-react-table";
import { useEffect } from "react";
import {
  NoticeListType,
  NoticeType,
  QuestionType,
} from "../../../types/boardManagementTypes";
import {
  MemberListType,
  MemberType,
} from "../../../types/memberManagementTypes";

interface CommonTableProps {
  data?: MemberListType | NoticeListType;
  columns: MRT_ColumnDef<MemberType | NoticeType | QuestionType, unknown>[];
  setSelectedRows?: (
    selectedRows: (MemberType | NoticeType | QuestionType)[],
  ) => void;
  checkBox?: boolean;
}

const CommonTable = ({
  data,
  columns,
  setSelectedRows,
  checkBox,
}: CommonTableProps) => {
  const indexColumn: MRT_ColumnDef<
    MemberType | NoticeType | QuestionType,
    unknown
  > = {
    header: "No",
    size: 50,
    Cell: ({ row }) => row.index + 1,
  };

  // 기존 컬럼 앞에 index 컬럼 추가
  const allColumns = [indexColumn, ...columns];
  const table = useMaterialReactTable<MemberType | NoticeType | QuestionType>({
    columns: allColumns,
    data: data?.items || [],
    enableRowSelection: checkBox,
    enableGlobalFilter: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableColumnFilters: false,
    enableHiding: false,
    enableColumnActions: false, // 컬럼 메뉴 버튼 비활성화
    enableColumnDragging: false, // 컬럼 드래그 비활성화
  });

  // rowSelection 상태를 별도의 변수로 추출
  const { rowSelection } = table.getState();

  useEffect(() => {
    const selectedRows = table
      .getSelectedRowModel()
      .flatRows.map((row) => row.original);
    setSelectedRows?.(selectedRows);
  }, [rowSelection, setSelectedRows, table]);

  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
};

export default CommonTable;
