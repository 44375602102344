import { useEffect } from "react";
import { useResetRecoilState } from "recoil";
import QuestionManagementList from "./QuestionManagementList";
import { questionManagementFilterAtom } from "../../../store/memberManagement/questionMnagementFilterStore";

function QuestionManagementTabContainer() {
  const resetFilter = useResetRecoilState(questionManagementFilterAtom);

  useEffect(() => {
    return () => {
      resetFilter();
    };
  }, [resetFilter]);

  return (
    <div id="content-wrapper" className="d-flex flex-column">
      <div id="content">
        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
          <h1 className="h5 mb-0 font-weight-bold text-gray-800">
            문의하기 관리
          </h1>
        </nav>

        <div className="container-fluid">
          <QuestionManagementList />
        </div>
      </div>
    </div>
  );
}

export default QuestionManagementTabContainer;
