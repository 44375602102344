import { useMutation, useQueryClient } from "react-query";
import MemberManagementApi from "../../services/memberManagement/MemberManagementApi";
import { MemberJoinStatusMutationParamsType } from "../../types/memberManagementTypes";

const useMemberJoinStatusMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (params: MemberJoinStatusMutationParamsType) => {
      return MemberManagementApi.fetchMemberJoinStatusUpdate({ ...params });
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("memberList");
        console.log("Mutation successful:", data);
      },
      onError: (error) => {
        console.error("Mutation error:", error);
      },
    },
  );
};

export default useMemberJoinStatusMutation;
