import { useEffect } from "react";
import { useResetRecoilState } from "recoil";
import NoticeManagementList from "./NoticeManagementList";
import { noticeManagementFilterAtom } from "../../../store/memberManagement/noticeManagementFilterStore";

function NoticeManagementTabContainer() {
  const resetFilter = useResetRecoilState(noticeManagementFilterAtom);
  useEffect(() => {
    return () => {
      resetFilter();
    };
  }, [resetFilter]);
  return (
    <div id="content-wrapper" className="d-flex flex-column">
      <div id="content">
        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
          <h1 className="h5 mb-0 font-weight-bold text-gray-800">
            공지사항 관리
          </h1>
        </nav>

        <div className="container-fluid">
          <NoticeManagementList />
        </div>
      </div>
    </div>
  );
}

export default NoticeManagementTabContainer;
