import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  OutlinedInput,
} from "@mui/material";

interface CommonSelectBoxProps {
  value: string | null;
  setValue: (event: SelectChangeEvent<string>) => void;
  options: Record<string, string>;
}

function CommonSelectBox({ value, setValue, options }: CommonSelectBoxProps) {
  return (
    <FormControl sx={{ m: 1, minWidth: 150 }} size="small" variant="outlined">
      <Select
        value={value ?? ""}
        displayEmpty
        onChange={(event) => setValue(event)}
        input={<OutlinedInput notched />}
      >
        {Object.entries(options).map(([label, val]) => (
          <MenuItem key={label} value={val}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default CommonSelectBox;
