// 컨피그 상수 변환

export interface ConfigType<T> {
  [key: string]: T;
}
export function translateValue<T>(
  key: string,
  codeTable: ConfigType<T>,
): T | string {
  return codeTable[key] || key;
}
