import HomeContainer from "../components/common/HomeContainer";
import MemberManagementTabContainer from "../components/template/memberManagement/MemberManagementTabContainer";
import NoticeManagementTabContainer from "../components/template/noticeManagement/NoticeManagementTabContainer";
import LoginForm from "../components/template/auth/LoginForm";
import NoticeManagementEditFormContainer from "../components/template/noticeManagement/NoticeManagementEditFormContainer";
import QuestionManagementTabContainer from "../components/template/questionManagement/QuestionManagementTabContainer";
import QuestionManagementEditFormContainer from "../components/template/questionManagement/QuestionManagementEditFormContainer";

const routes = [
  {
    path: "/",
    element: <LoginForm />,
  },
  {
    path: "/",
    element: <HomeContainer />,
    children: [
      {
        path: "/member-management",
        element: <MemberManagementTabContainer />,
      },
      {
        path: "/notice-management",
        element: <NoticeManagementTabContainer />,
      },
      // 공지사항 등록
      {
        path: "/notice-management/notice-form",
        element: <NoticeManagementEditFormContainer />,
      },
      {
        path: "/question-management",
        element: <QuestionManagementTabContainer />,
      },
      // 문의하기 등록/상세
      {
        path: "/question-management/question-form",
        element: <QuestionManagementEditFormContainer />,
      },
    ],
  },

  //
  // // 회원관리
  // {
  //   path: "/member-management",
  //   element: <MemberManagementTabContainer />,
  // },
  //
  // // 공지사항 관리
  // {
  //   path: "/notice-management",
  //   element: <NoticeManagementTabContainer />,
  // },
];

export default routes;
