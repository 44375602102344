import { Outlet } from "react-router-dom";
import SideNavigationBar from "./SideNavigationBar";

function HomeContainer() {
  return (
    <div id="wrapper">
      <SideNavigationBar />

      {/* 탭 컨텐츠 */}
      <Outlet />
    </div>
  );
}

export default HomeContainer;
