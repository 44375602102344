import { Box, Button, FormLabel, Paper } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import Grid from "@mui/material/Grid2";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Swal from "sweetalert2";
import { useEffect } from "react";
import moment from "moment";
import { userAtom } from "../../../store/common/userStore";
import { NoticeInfoType } from "../../../types/boardManagementTypes";
import logo from "../../../assets/logo512.png";
import useBoardReplyAddMutation from "../../../hook/boardManagement/useBoardReplyAddMutation";
import { translateValue } from "../../../util/util";
import { memberTypeConfig } from "../../../config/config";

const LabelBox = ({ label }: { label: any }) => (
  <Grid size={2}>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        border: "1px solid #ccc",
        padding: 1,
        height: "100%",
      }}
    >
      {typeof label === "string" ? <FormLabel>{label}</FormLabel> : label}
    </Box>
  </Grid>
);

const ContentBox = ({ children, xs = 10 }: { children: any; xs?: number }) => (
  <Grid size={xs}>
    <Box
      sx={{
        // display: "flex",
        alignItems: "center",
        border: "1px solid #ccc",
        padding: 1,
        height: "100%",
      }}
    >
      {children}
    </Box>
  </Grid>
);

const QuestionForm = ({ questionInfo }: { questionInfo?: NoticeInfoType }) => {
  const navigate = useNavigate();
  const { control, handleSubmit, reset } = useForm();
  const replyAddMutation = useBoardReplyAddMutation();
  const user = useRecoilValue(userAtom);

  useEffect(() => {
    if (questionInfo) {
      reset({
        content: questionInfo.replyContent || "",
      });
    }
  }, [questionInfo, reset]);

  const onSubmit = (data: any) => {
    if (!data.content) {
      alert("답변 내용을 입력해주세요.");
    } else {
      Swal.fire({
        text: `답변 등록 시 문의 등록자에게 알림이 가며, 답변을 수정할 수 없습니다. 답변을 등록하시겠습니까?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "예",
        cancelButtonText: "아니오",
      }).then((result) => {
        if (result.isConfirmed) {
          replyAddMutation.mutate({
            id: String(questionInfo?.id),
            replyContent: data.content,
            replyMemberCode: String(user.member.id),
          });
        }
      });
    }
  };

  return (
    <Paper sx={{ padding: 2, maxWidth: "95%" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container alignItems="stretch">
          {/* 1번째 줄 */}
          <LabelBox label="회원 유형" />
          <ContentBox xs={4}>
            {translateValue(questionInfo?.memberType || "", memberTypeConfig)}
          </ContentBox>
          <LabelBox label="문의 등록자" />
          <ContentBox xs={4}>{questionInfo?.createdMemberName}</ContentBox>
          {/* 2번째 줄 */}
          <LabelBox label="문의 등록일" />
          <ContentBox xs={4}>
            {moment(questionInfo?.createdAt).format("YYYY.MM.DD")}
          </ContentBox>
          <LabelBox label="답변 여부" />
          <ContentBox xs={4}>{questionInfo?.replyFlag || "N"}</ContentBox>
          {/* 3번째 줄 */}
          <LabelBox label="문의 내용" />
          <ContentBox>
            <div> {questionInfo?.boardContent}</div>
            <div>
              {" "}
              <Grid
                container
                justifyContent="center"
                spacing={2}
                sx={{ marginTop: 2 }}
              >
                {[1, 2, 3].map((_, index) => (
                  <Grid
                    key={index}
                    sx={{
                      width: { xs: "100%", md: "30%", lg: "30%" }, // 반응형 너비 설정
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      component="img"
                      src={logo}
                      alt={`Image ${index + 1}`}
                      sx={{
                        width: "100%",
                        height: "auto",
                        objectFit: "cover",
                        padding: 1,
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          </ContentBox>

          {/* 4번째 줄 */}
          <LabelBox label="답변 내용" />
          {questionInfo?.replyFlag === "Y" ? (
            <ContentBox xs={10}>
              <Box
                sx={{}}
                dangerouslySetInnerHTML={{ __html: questionInfo?.replyContent }}
              />
            </ContentBox>
          ) : (
            <Grid size={10}>
              <Box
                sx={{ border: "1px solid #ccc", padding: 1, height: "360px" }}
              >
                <Controller
                  name="content"
                  control={control}
                  render={({ field }) => (
                    <ReactQuill {...field} style={{ height: "300px" }} />
                  )}
                />
              </Box>
            </Grid>
          )}

          {questionInfo?.replyFlag === "Y" && (
            <>
              <LabelBox label="답변 등록일" />
              <ContentBox xs={4}>
                {new Date(questionInfo?.updatedAt || "").toLocaleString()}
              </ContentBox>
              <LabelBox label="답변 등록자" />
              <ContentBox xs={4}>{questionInfo?.replyMemberName}</ContentBox>
            </>
          )}
          {/* 취소, 등록 버튼 */}
          <Grid size={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: 1,
              }}
            >
              {questionInfo?.replyFlag === "Y" ? (
                <Button
                  type="submit"
                  variant="contained"
                  onClick={() => navigate("/question-management")}
                >
                  목록
                </Button>
              ) : (
                <>
                  <Button
                    variant="contained"
                    style={{ background: "grey" }}
                    sx={{ marginRight: 1 }}
                    onClick={() => navigate("/question-management")}
                  >
                    취소
                  </Button>
                  <Button type="submit" variant="contained" color="error">
                    등록
                  </Button>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default QuestionForm;
