import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import { FormLabel, TextField, InputAdornment, Button } from "@mui/material";
import { useRecoilState } from "recoil";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "react-query";
import CommonSelectBox from "../../common/form/CommonSelectBox";
import { memberManagementFilterAtom } from "../../../store/memberManagement/memberManagementFilterStore";
import {
  businessSearchTypeOptions,
  signStatusOptions,
  tutorParentSearchTypeOptions,
} from "../../../config/config";
import { MemberListType } from "../../../types/memberManagementTypes";

function MemberManagementFilter({
  type,
  refetch,
}: {
  type: string;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<MemberListType, Error>>;
}) {
  const [filter, setFilter] = useRecoilState(memberManagementFilterAtom);

  const handleSignStatusChange = (event: any) => {
    setFilter((prevState) => ({
      ...prevState,
      joinStatus: event.target.value || null,
    }));
  };

  const handleSearchTypeChange = (event: any) => {
    setFilter((prevState) => ({
      ...prevState,
      searchType: event.target.value,
    }));
  };

  const handleSearchTextChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFilter((prevState) => ({
      ...prevState,
      searchText: event.target.value,
    }));
  };

  // 검색 버튼 클릭 시 필터 업데이트
  const handleSearch = () => {
    refetch();
  };

  // 리셋 버튼 클릭 시 필터 초기화
  const handleReset = () => {
    setFilter({
      joinStatus: null,
      searchType: "EMAIL",
      searchText: "",
      mobileModifyStatus: null,
    });
  };

  const sx = {
    display: "flex",
    alignItems: "center",
    padding: 1,
    height: "100%",
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <>
      <Grid
        container
        alignItems="stretch"
        sx={{ p: 1, marginTop: "15px", border: "1px solid #ccc" }}
      >
        {type !== "tutorParent" && (
          <>
            <Grid size={1}>
              <Box sx={sx}>
                <FormLabel>가입 상태</FormLabel>
              </Box>
            </Grid>
            <Grid size={1.5}>
              <Box sx={sx}>
                <CommonSelectBox
                  options={signStatusOptions}
                  value={filter.joinStatus}
                  setValue={handleSignStatusChange}
                />
              </Box>
            </Grid>

            <Grid size={9.5} />
          </>
        )}

        <Grid size={1}>
          <Box sx={sx}>
            <FormLabel>검색</FormLabel>
          </Box>
        </Grid>

        <Grid size={9}>
          <Box sx={sx}>
            <CommonSelectBox
              options={
                type === "tutorParent"
                  ? tutorParentSearchTypeOptions
                  : businessSearchTypeOptions
              }
              value={filter.searchType}
              setValue={handleSearchTypeChange}
            />
            <TextField
              style={{ marginLeft: "5px" }}
              id="outlined-basic"
              label="검색어를 입력해주세요"
              onKeyDown={handleKeyDown}
              variant="outlined"
              size="small"
              value={filter.searchText}
              onChange={handleSearchTextChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>

        <Grid size={2}>
          <Box sx={sx}>
            <Button color="info" variant="contained" onClick={handleSearch}>
              검색
            </Button>
            <Button
              style={{ marginLeft: "5px" }}
              color="info"
              variant="outlined"
              onClick={handleReset}
            >
              <RestartAltIcon color="primary" />
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default MemberManagementFilter;
