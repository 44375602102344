import Box from "@mui/material/Box";
import {
  FormLabel,
  TextField,
  InputAdornment,
  Button,
  FormControlLabel,
} from "@mui/material";
import { useRecoilState } from "recoil";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "react-query";
import Checkbox from "@mui/material/Checkbox";
import CommonSelectBox from "../../common/form/CommonSelectBox";
import { noticeSearchTypeOptions, ynFlagOptions } from "../../../config/config";
import { NoticeListType } from "../../../types/boardManagementTypes";
import { noticeManagementFilterAtom } from "../../../store/memberManagement/noticeManagementFilterStore";

function NoticeManagementFilter({
  refetch,
}: {
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<NoticeListType, Error>>;
}) {
  const [filter, setFilter] = useRecoilState(noticeManagementFilterAtom);

  const handleCheckboxChange = (value: string) => {
    setFilter((prevState) => {
      const currentValues = prevState.memberTypes
        ? prevState.memberTypes.split(",")
        : [];

      // 값이 이미 있는 경우 제거, 없는 경우 추가
      const newValues = currentValues.includes(value)
        ? currentValues.filter((item) => item !== value)
        : [...currentValues, value];

      return {
        ...prevState,
        memberTypes: newValues.join(","),
      };
    });
  };

  const handleViewFlagChange = (event: any) => {
    setFilter((prevState) => ({
      ...prevState,
      viewFlag: event.target.value || null,
    }));
  };

  const handleTopFlagChange = (event: any) => {
    setFilter((prevState) => ({
      ...prevState,
      topFlag: event.target.value || null,
    }));
  };

  const handleSearchTypeChange = (event: any) => {
    setFilter((prevState) => ({
      ...prevState,
      searchType: event.target.value,
    }));
  };

  const handleSearchTextChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFilter((prevState) => ({
      ...prevState,
      searchText: event.target.value,
    }));
  };

  // 검색 버튼 클릭 시 필터 업데이트
  const handleSearch = () => {
    refetch();
  };

  // 리셋 버튼 클릭 시 필터 초기화
  const handleReset = () => {
    setFilter({
      memberTypes: "",
      searchType: "title",
      searchText: "",
      viewFlag: null,
      topFlag: null,
    });
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <>
      <Box sx={{ p: 1, mt: "15px", border: "1px solid #ccc" }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <FormLabel sx={{ width: "5rem" }}>회원유형</FormLabel>
          <Box sx={{ marginLeft: "1rem" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filter.memberTypes?.includes("BUSINESS") || false}
                  onChange={() => handleCheckboxChange("BUSINESS")}
                />
              }
              label="사업자"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filter.memberTypes?.includes("TUTOR") || false}
                  onChange={() => handleCheckboxChange("TUTOR")}
                />
              }
              label="선생님"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filter.memberTypes?.includes("PARENT") || false}
                  onChange={() => handleCheckboxChange("PARENT")}
                />
              }
              label="학부모"
            />
          </Box>

          <FormLabel sx={{ marginLeft: "2rem" }}>전시여부</FormLabel>
          <Box sx={{ marginLeft: "1rem" }}>
            <CommonSelectBox
              options={ynFlagOptions}
              value={filter.viewFlag}
              setValue={handleViewFlagChange}
            />
          </Box>

          <FormLabel sx={{ marginLeft: "2rem" }}>상단노출</FormLabel>
          <Box sx={{ marginLeft: "1rem" }}>
            <CommonSelectBox
              options={ynFlagOptions}
              value={filter.topFlag}
              setValue={handleTopFlagChange}
            />
          </Box>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <FormLabel sx={{ width: "5rem" }}>검색</FormLabel>
          <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
            <CommonSelectBox
              options={noticeSearchTypeOptions}
              value={filter.searchType}
              setValue={handleSearchTypeChange}
            />
            <TextField
              style={{ marginLeft: "5px" }}
              id="outlined-basic"
              label="검색어를 입력해주세요"
              onKeyDown={handleKeyDown}
              variant="outlined"
              size="small"
              value={filter.searchText}
              onChange={handleSearchTextChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button color="info" variant="contained" onClick={handleSearch}>
              검색
            </Button>
            <Button
              style={{ marginLeft: "5px" }}
              color="info"
              variant="outlined"
              onClick={handleReset}
            >
              <RestartAltIcon color="primary" />
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default NoticeManagementFilter;
