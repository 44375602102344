import { useQuery, UseQueryResult } from "react-query";
import { NoticeInfoType } from "../../types/boardManagementTypes";
import BoardManagementApi from "../../services/boardManagement/BoardManagementApi";

const useBoardInfoQuery = ({
  params,
  enabled,
}: {
  params: string;
  enabled: boolean;
}): UseQueryResult<NoticeInfoType, Error> => {
  return useQuery<NoticeInfoType, Error>({
    queryKey: ["noticeList", params],
    queryFn: () => BoardManagementApi.fetchBoardInfo(params),
    enabled, // enabled 속성으로 조건부 실행 제어
    onSuccess: (data: NoticeInfoType) => {
      console.log("Query successful:", data);
    },
    onError: (error) => {
      console.error("Query error:", error);
    },
  });
};

export default useBoardInfoQuery;
