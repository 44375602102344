import { useQuery, UseQueryResult } from "react-query";

import {
  BoardListQueryParamsType,
  NoticeListQueryParamsType,
  NoticeListType,
} from "../../types/boardManagementTypes";
import BoardManagementApi from "../../services/boardManagement/BoardManagementApi";

const useBoardListQuery = ({
  params,
  endPoint,
}: {
  params: NoticeListQueryParamsType | BoardListQueryParamsType;
  endPoint: string;
}): UseQueryResult<NoticeListType, Error> => {
  return useQuery<NoticeListType, Error>({
    queryKey: ["noticeList", endPoint],
    queryFn: () => BoardManagementApi.fetchBoardList({ ...params }, endPoint),
    onSuccess: (data: NoticeListType) => {
      console.log("Query successful:", data);
    },
    onError: (error) => {
      console.error("Query error:", error);
    },
  });
};

export default useBoardListQuery;
