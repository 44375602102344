import Box from "@mui/material/Box";
import { useRecoilValue } from "recoil";
import { MRT_ColumnDef } from "material-react-table";
import moment from "moment/moment";
import { Button } from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import CommonTable from "../../common/table/CommonTable";
import NoticeManagementFilter from "./NoticeManagementFilter";
import useBoardListQuery from "../../../hook/boardManagement/useBoardListQuery";
import { noticeManagementFilterAtom } from "../../../store/memberManagement/noticeManagementFilterStore";
import { NoticeType, QuestionType } from "../../../types/boardManagementTypes";
import { MemberType } from "../../../types/memberManagementTypes";
import useBoardDeleteMutation from "../../../hook/boardManagement/useBoardDeleteMutation";

const NoticeManagementList = () => {
  const navigate = useNavigate();
  const filterParams = useRecoilValue(noticeManagementFilterAtom);
  const { data: noticeList, refetch } = useBoardListQuery({
    params: filterParams,
    endPoint: "notice",
  });
  const [selectedRows, setSelectedRows] = useState<
    (MemberType | NoticeType | QuestionType)[]
  >([]);
  const noticeDeleteMutation = useBoardDeleteMutation();

  const noticeColumns: MRT_ColumnDef<
    MemberType | NoticeType | QuestionType,
    unknown
  >[] = [
    {
      accessorKey: "boardTitle",
      header: "제목",
      Cell: ({ row }: { row: any }) => {
        return (
          <Link
            to="/notice-management/notice-form"
            state={row.original.id}
            style={{
              textDecoration: "underline",
              color: "black",
            }}
          >
            {row.original.boardTitle}
          </Link>
        );
      },
    },
    { accessorKey: "viewFlag", header: "전시여부" },
    { accessorKey: "topFlag", header: "상단노출" },
    { accessorKey: "viewCount", header: "조회수" },
    {
      accessorKey: "createdAt",
      header: "등록일",
      Cell: ({ row }: { row: any }) => {
        const date = row.original.createdAt;
        return date ? moment(row.original.createdAt).format("YYYY.MM.DD") : "";
      },
    },
    { accessorKey: "createdMemberName", header: "등록자" },
    {
      accessorKey: "updatedAt",
      header: "최종 수정일",
      Cell: ({ row }: { row: any }) => {
        const date = row.original.updatedAt;
        return date ? moment(row.original.updatedAt).format("YYYY.MM.DD") : "";
      },
    },
    { accessorKey: "updatedMemberName", header: "최종 수정자" },
  ];

  const handleDelete = () => {
    if (selectedRows.length > 0) {
      Swal.fire({
        text: "선택한 목록을 삭제하시겠습니까?",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "예",
        cancelButtonText: "아니오",
      }).then((result) => {
        if (result.isConfirmed) {
          noticeDeleteMutation.mutate({
            id: String(selectedRows[0].id),
          });
        }
      });
    }
  };

  const handleCreate = () => {
    navigate("/notice-management/notice-form");
  };

  return (
    <Box sx={{ bgcolor: "background.paper" }}>
      <div style={{ padding: "18px" }}>
        <NoticeManagementFilter refetch={refetch} />
      </div>

      <div
        style={{
          paddingRight: "2rem",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          style={{ background: "grey" }}
          variant="contained"
          onClick={handleDelete}
        >
          삭제
        </Button>
        <Button
          style={{ marginLeft: "5px" }}
          color="error"
          variant="contained"
          onClick={handleCreate}
        >
          등록
        </Button>
      </div>

      <Box sx={{ p: 3 }}>
        <CommonTable
          checkBox
          columns={noticeColumns}
          data={noticeList}
          setSelectedRows={setSelectedRows}
        />
      </Box>
    </Box>
  );
};

export default NoticeManagementList;
