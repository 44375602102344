import { useMutation, useQueryClient } from "react-query";
import BoardManagementApi from "../../services/boardManagement/BoardManagementApi";

const useBoardDeleteMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (params: { id: string }) => {
      return BoardManagementApi.fetchBoardDelete({ ...params });
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("noticeList");
        console.log("Mutation successful:", data);
      },
      onError: (error) => {
        console.error("Mutation error:", error);
      },
    },
  );
};

export default useBoardDeleteMutation;
