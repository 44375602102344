import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useEffect } from "react";
import mainlogo from "../../../assets/img/symbol.png";
import { useLoginMutation } from "../../../hook/auth/useLoginMutation";

interface LoginFormInputs {
  email: string;
  password: string;
  autoLogin: boolean;
}

function LoginForm() {
  const navigate = useNavigate();
  const [cookies] = useCookies(["accessToken"]);

  useEffect(() => {
    if (cookies.accessToken) {
      navigate("/member-management");
    }
  }, [cookies.accessToken, navigate]);
  const methods = useForm<LoginFormInputs>({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
      autoLogin: false,
    },
  });

  const { register, watch, handleSubmit } = methods;

  const mutation = useLoginMutation();

  // 3. onSubmit 함수에 autoLogin 처리 추가
  const onSubmit = (formData: LoginFormInputs) => {
    const emailWatch = watch("email");
    const passwordWatch = watch("password");

    if (!emailWatch) {
      alert("아이디를 입력해주세요.");
    } else if (!passwordWatch) {
      alert("비밀번호를 입력해주세요.");
    } else {
      mutation.mutate({
        loginId: formData.email,
        loginPassword: formData.password,
        autoLogin: formData.autoLogin,
      });
    }
  };

  return (
    <div className="container" style={{ maxWidth: "40%" }}>
      <div className="p-5">
        <div className="bg-login-image text-center">
          <img src={mainlogo} width="100" alt={"symbol"} />
        </div>
        <div className="text-center">
          <h1 className="h5 text-gray-900 mb-4 mt-4">
            <strong>관리자 로그인</strong>
          </h1>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <input
              {...register("email")}
              type="id"
              className="form-control form-control-user"
              placeholder="이메일을 입력해주세요."
            />
          </div>
          <div className="form-group">
            <input
              {...register("password")}
              type="password"
              className="form-control form-control-user"
              placeholder="비밀번호를 입력해주세요"
            />
          </div>
          <div className="form-group">
            <div className="custom-control custom-checkbox small">
              <input
                {...register("autoLogin")} // 4. checkbox를 form과 연결
                type="checkbox"
                className="custom-control-input"
                id="customCheck"
              />
              <label className="custom-control-label" htmlFor="customCheck">
                자동 로그인
              </label>
            </div>
          </div>
          <button type="submit" className="btn btn-primary btn-user btn-block">
            Login
          </button>
        </form>
      </div>
    </div>
  );
}

export default LoginForm;
