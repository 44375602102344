import { MRT_ColumnDef } from "material-react-table";
import moment from "moment/moment";
import { MemberType } from "../types/memberManagementTypes";
import { NoticeType, QuestionType } from "../types/boardManagementTypes";

export const signStatusOptions = {
  전체: "",
  승인대기: "REQUEST",
  승인완료: "ACCEPT",
  거절완료: "REJECT",
};

export const businessSearchTypeOptions = {
  "아이디(이메일)": "EMAIL",
  이름: "NAME",
  사업자명: "BUSINESS_NAME",
  사업자번호: "BUSINESS_NO",
};

export const memberTypeOptions = {
  전체: "",
  사업자: "BUSINESS",
  선생님: "TUTOR",
  학부모: "PARENTS",
};

export const tutorParentSearchTypeOptions = {
  "아이디(이메일)": "EMAIL",
  이름: "NAME",
};

export const tutorParentColumns: MRT_ColumnDef<
  MemberType | NoticeType | QuestionType,
  unknown
>[] = [
  { accessorKey: "email", header: "아이디(이메일)" },
  { accessorKey: "name", header: "이름" },
  {
    accessorKey: "mobile",
    header: "휴대폰번호",
  },
  {
    accessorKey: "city",
    header: "가입일",
    Cell: ({ row }: { row: any }) => {
      const date = row.original.createdAt;
      return date ? moment(row.original.createdAt).format("YYYY.MM.DD") : "";
    },
  },
];

export const signStatusConfig = {
  REQUEST: "승인대기",
  ACCEPT: "승인완료",
  REJECT: "거절완료",
};

export const memberTypeConfig = {
  BUSINESS: "사업자",
  TUTOR: "선생님",
  PARENTS: "학부모",
};

export const noticeSearchTypeOptions = {
  제목: "title",
  등록자: "register",
  최종수정자: "modifier",
};

export const questionSearchTypeOptions = {
  "문의 내용": "title",
  "문의 등록자": "register",
  "답변 등록자": "modifier",
};

export const ynFlagOptions = {
  전체: "",
  Y: "Y",
  N: "N",
};
