import { atom } from "recoil";
import { MemberListQueryParamsType } from "../../types/memberManagementTypes";

export const memberManagementFilterAtom = atom<MemberListQueryParamsType>({
  key: "memberManagementFilter",
  default: {
    joinStatus: null,
    mobileModifyStatus: null,
    searchType: "EMAIL",
    searchText: "",
  },
});
