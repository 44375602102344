import { useResetRecoilState } from "recoil";
import { useEffect } from "react";
import MemberManagementTabList from "./MemberManagementTabList";
import { memberManagementFilterAtom } from "../../../store/memberManagement/memberManagementFilterStore";

function MemberManagementTabContainer() {
  const resetFilter = useResetRecoilState(memberManagementFilterAtom);
  useEffect(() => {
    return () => {
      resetFilter();
    };
  }, [resetFilter]);
  return (
    <div id="content-wrapper" className="d-flex flex-column">
      <div id="content">
        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
          <h1 className="h5 mb-0 font-weight-bold text-gray-800">회원 관리</h1>
        </nav>

        <div className="container-fluid">
          <MemberManagementTabList />
        </div>
      </div>
    </div>
  );
}

export default MemberManagementTabContainer;
