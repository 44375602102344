import { To, useLocation, useNavigate } from "react-router-dom";
import { SetStateAction, useEffect, useState } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { Button } from "@mui/material";
import { useCookies } from "react-cookie";
import mainlogo from "../../assets/img/symbol_hori.png";
import { userAtom } from "../../store/common/userStore";

const navItems = [
  {
    label: "회원 관리",
    url: "/member-management",
  },
  {
    label: "공지사항 관리",
    url: "/notice-management",
  },
  // {
  //   label: "자주 묻는 질문 관리",
  //   url: "/requested-course",
  // },
  {
    label: "문의하기 관리",
    url: "/question-management",
  },
  // {
  //   label: "약관 관리",
  //   url: "/requested-course",
  // },
  // {
  //   label: "마케팅 정보 앱 푸시 알림 전송",
  //   url: "/requested-course",
  // },
  // {
  //   label: "통계",
  //   url: "/requested-course",
  // },
];

function SideNavigationBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(0);
  const user = useRecoilValue(userAtom);
  const [, , removeCookie] = useCookies(["accessToken"]);
  const resetUser = useResetRecoilState(userAtom);

  const handleItemClick = (
    index: SetStateAction<number>,
    url: To,
    label: string,
  ) => {
    setActiveIndex(index);
    navigate(url, { state: label });
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const currentIndex = navItems.findIndex((item) => item.url === currentPath);
    if (currentIndex !== -1) {
      setActiveIndex(currentIndex);
    }
  }, [location.pathname]);

  const onLogOutClick = () => {
    removeCookie("accessToken", {
      path: "/",
      secure: true,
    });
    resetUser();
  };

  return (
    <>
      <ul
        className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
        id="accordionSidebar"
      >
        {/* Sidebar - Brand */}
        <div className="sidebar-brand d-flex align-items-center justify-content-center">
          <div className="sidebar-brand-icon rotate-n-15">
            <i className="fas fa-laugh-wink" />
          </div>
          <div className="bg-login-image">
            <img src={mainlogo} width="100" alt={"symbol"} />
          </div>
        </div>
        {/* Divider */}
        <hr className="sidebar-divider my-0" />

        {/* Nav Item - Dashboard */}
        <li className="nav-item">
          <div className="nav-link">
            <i className="fas fa-fw fa-tachometer-alt" />
            <span>
              <strong> {user.member.name}</strong>님
            </span>
            <div className={"mt-2"}>
              <Button
                onClick={onLogOutClick}
                size="small"
                color="info"
                variant="contained"
              >
                로그아웃
              </Button>
            </div>
          </div>
        </li>

        {/* Divider */}
        <hr className="sidebar-divider" />

        {navItems.map((item, index) => (
          // active
          <li
            className={`nav-item ${activeIndex === index && "active"}`}
            role={"presentation"}
            key={index}
            onClick={() => handleItemClick(index, item.url, item.label)}
          >
            <p className="nav-link">
              <i className="fas fa-fw fa-chart-area" />
              <span>{item.label}</span>
            </p>
          </li>
        ))}

        {/* Divider */}
        <hr className="sidebar-divider d-none d-md-block" />
      </ul>
    </>
  );
}

export default SideNavigationBar;
