import { useMutation } from "react-query";
import { useCookies } from "react-cookie";
import axios from "axios";
import { useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { LoginQueryResType } from "../../types/authTypes";
import MemberManagementApi from "../../services/memberManagement/MemberManagementApi";
import { userAtom } from "../../store/common/userStore";

interface LoginParams {
  loginId: string;
  loginPassword: string;
  autoLogin?: boolean;
}

const fetchLogin = async ({
  loginId,
  loginPassword,
  autoLogin,
}: LoginParams): Promise<any> => {
  const baseUrl = MemberManagementApi.getBaseUrl();
  try {
    const response = await axios.post(`${baseUrl}/api/v1/auth/login`, {
      loginId,
      loginPassword,
      memberType: "ADMIN",
    });

    return { ...response, autoLogin };
  } catch (error) {
    console.error("Login failed:", error);
    throw error;
  }
};

export const useLoginMutation = () => {
  const [, setCookie, removeCookie] = useCookies(["accessToken"]);
  const setUser = useSetRecoilState(userAtom);
  const navigate = useNavigate();

  return useMutation(fetchLogin, {
    onSuccess: async (res: LoginQueryResType & { autoLogin?: boolean }) => {
      const { autoLogin } = res;

      // 자동 로그인 설정에 따른 쿠키 만료 시간 설정
      const expiryDate = autoLogin
        ? new Date(Date.now() + 30 * 24 * 60 * 60 * 1000) // 30일
        : new Date(res.exp);

      // accessToken 설정
      setCookie("accessToken", res.accessToken, {
        path: "/",
        expires: expiryDate,
      });

      setUser({
        profile: { id: null, name: "" },
        member: {
          id: res.member.id,
          loginId: res.member.loginId,
          name: res.member.name,
          memberType: res.member.memberType,
          memberStatus: res.member.memberStatus,
          authDate: "",
        },
      });

      // 로컬 스토리지에 자동 로그인 설정 저장
      if (autoLogin) {
        localStorage.setItem("autoLogin", "true");
      } else {
        localStorage.removeItem("autoLogin");
      }

      navigate("/member-management");
    },
    onError: (error) => {
      console.error("Login failed:", error);
      removeCookie("accessToken");
      localStorage.removeItem("autoLogin");
    },
  });
};
