import { Suspense, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useRecoilValue } from "recoil";
import { useLocation, useNavigate } from "react-router-dom";
import { userAtom } from "../../store/common/userStore";
import ReactRoute from "../../routes/ReactRoute";

const GlobalWrapper = () => {
  const [cookies] = useCookies(["accessToken"]);
  const user = useRecoilValue(userAtom);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      (!cookies.accessToken || !user.member.id) &&
      !["/"].includes(location.pathname)
    ) {
      navigate("/");
    }
  }, [cookies, location.pathname, navigate, user.member.id]);

  return (
    <>
      <Suspense fallback="loading...">
        <div id="app-body">
          <div>
            <ReactRoute />
          </div>
        </div>
      </Suspense>
    </>
  );
};

export default GlobalWrapper;
