import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import BoardManagementApi from "../../services/boardManagement/BoardManagementApi";

const useBoardUpdateMutation = () => {
  const navigate = useNavigate();
  return useMutation(
    ({ params, endPoint }: { params: FormData; endPoint: string }) => {
      return BoardManagementApi.fetchBoardUpdate(params, endPoint);
    },
    {
      onSuccess: (data) => {
        navigate("/notice-management");
        console.log("Mutation successful:", data);
      },
      onError: (error) => {
        console.error("Mutation error:", error);
      },
    },
  );
};

export default useBoardUpdateMutation;
