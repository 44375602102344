import { useQuery, UseQueryResult } from "react-query";
import MemberManagementApi from "../../services/memberManagement/MemberManagementApi";
import {
  MemberListQueryParamsType,
  MemberListType,
} from "../../types/memberManagementTypes";

const useMemberListQuery = ({
  params,
  endPoint,
}: {
  params: MemberListQueryParamsType;
  endPoint: string;
}): UseQueryResult<MemberListType, Error> => {
  return useQuery<MemberListType, Error>({
    queryKey: ["memberList", endPoint],
    queryFn: () => MemberManagementApi.fetchMemberList({ ...params }, endPoint),
    onSuccess: (data: MemberListType) => {
      console.log("Query successful:", data);
    },
    onError: (error) => {
      console.error("Query error:", error);
    },
  });
};

export default useMemberListQuery;
