import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import { UserType } from "../../types/authTypes";

const { persistAtom } = recoilPersist();

export const userAtom = atom<UserType>({
  key: "user",
  default: {
    member: {
      id: 0,
      loginId: "",
      name: "",
      memberType: "",
      memberStatus: "",
      authDate: "",
    },
    profile: {
      id: null,
      name: "",
    },
  },
  effects_UNSTABLE: [persistAtom],
});
