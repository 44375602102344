import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";

import Grid from "@mui/material/Grid2";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Swal from "sweetalert2";
import { useEffect } from "react";
import useBoardAddMutation from "../../../hook/boardManagement/useBoardAddMutation";
import { userAtom } from "../../../store/common/userStore";
import { NoticeInfoType } from "../../../types/boardManagementTypes";
import useBoardUpdateMutation from "../../../hook/boardManagement/useBoardUpdateMutation";

const LabelBox = ({ label }: { label: any }) => (
  <Grid size={2}>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        border: "1px solid #ccc",
        padding: 1,
        height: "100%",
      }}
    >
      {typeof label === "string" ? <FormLabel>{label}</FormLabel> : label}
    </Box>
  </Grid>
);

const ContentBox = ({ children, xs = 10 }: { children: any; xs?: number }) => (
  <Grid size={xs}>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        border: "1px solid #ccc",
        padding: 1,
        height: "100%",
      }}
    >
      {children}
    </Box>
  </Grid>
);

const NoticeForm = ({
  noticeInfo,
  type,
}: {
  noticeInfo?: NoticeInfoType;
  type: string;
}) => {
  const navigate = useNavigate();
  const { control, handleSubmit, watch, register, reset } = useForm();
  const addNoticeMutation = useBoardAddMutation();
  const updateNoticeMutation = useBoardUpdateMutation();
  const selectedMembers = watch("memberType", []);
  const user = useRecoilValue(userAtom);

  useEffect(() => {
    if (noticeInfo) {
      reset({
        memberType: noticeInfo.memberTypes?.split(",") || [],
        viewFlag: noticeInfo.viewFlag || "N",
        topFlag: noticeInfo.topFlag || "N",
        title: noticeInfo.boardTitle || "",
        content: noticeInfo.boardContent || "",
      });
    }
  }, [noticeInfo, reset]);

  const onSubmit = (data: any) => {
    if (!data.title) {
      alert("제목을 입력해주세요.");
    } else if (!data.content) {
      alert("내용을 입력해주세요.");
    } else if (selectedMembers.length < 1) {
      alert("회원 유형을 선택해주세요.");
    } else {
      const formDataToSend = new FormData();
      formDataToSend.append("boardTitle", data.title);
      formDataToSend.append("boardContent", data.content);
      formDataToSend.append("boardKind", `NOTICE`);
      formDataToSend.append("memberTypes", selectedMembers.join(","));
      formDataToSend.append("createdMemberCode", String(user.member.id));
      formDataToSend.append(
        "updatedMemberCode",
        type === "modify" ? String(user.member.id) : "",
      );
      formDataToSend.append("viewFlag", data.viewFlag);
      formDataToSend.append("topFlag", data.topFlag);

      Swal.fire({
        text: `공지사항을 ${type === "add" ? "등록" : "수정"} 하시겠습니까?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "예",
        cancelButtonText: "아니오",
      }).then((result) => {
        if (result.isConfirmed) {
          if (type === "add") {
            addNoticeMutation.mutate(formDataToSend);
          } else if (type === "modify") {
            updateNoticeMutation.mutate({
              params: formDataToSend,
              endPoint: String(noticeInfo?.id),
            });
          }
        }
      });
    }
  };

  const memberOptions = [
    { label: "사업자", value: "BUSINESS" },
    { label: "선생님", value: "TUTOR" },
    { label: "학부모", value: "PARENTS" },
  ];

  return (
    <Paper sx={{ padding: 2, maxWidth: "95%" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container alignItems="stretch">
          {/* 1번째 줄 */}
          <LabelBox label="회원유형" />
          <ContentBox>
            {memberOptions.map((option) => (
              <Controller
                key={option.value}
                name="memberType"
                control={control}
                defaultValue={noticeInfo?.memberTypes?.split(",") || []}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={value?.includes(option.value)}
                        onChange={(e) => {
                          const newSelected = e.target.checked
                            ? [...value, option.value]
                            : value.filter(
                                (val: string) => val !== option.value,
                              );
                          onChange(newSelected);
                        }}
                      />
                    }
                    label={option.label}
                  />
                )}
              />
            ))}
          </ContentBox>

          {/* 2번째 줄 */}
          <LabelBox label="전시여부" />
          <ContentBox xs={4}>
            <Controller
              name="viewFlag"
              control={control}
              defaultValue={noticeInfo?.viewFlag || "N"}
              render={({ field }) => (
                <RadioGroup row {...field}>
                  <FormControlLabel value="Y" control={<Radio />} label="Y" />
                  <FormControlLabel value="N" control={<Radio />} label="N" />
                </RadioGroup>
              )}
            />
          </ContentBox>
          <LabelBox label="상단 노출" />
          <ContentBox xs={4}>
            <Controller
              name="topFlag"
              control={control}
              defaultValue={noticeInfo?.topFlag || "N"}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={value === "Y"}
                      onChange={(e) => onChange(e.target.checked ? "Y" : "N")}
                    />
                  }
                  label="Y"
                />
              )}
            />
          </ContentBox>

          {/* 3번째 줄 */}
          <LabelBox label="제목" />
          <ContentBox>
            <TextField fullWidth variant="outlined" {...register("title")} />
          </ContentBox>

          {/* 4번째 줄 */}
          <LabelBox label="내용" />
          <Grid size={10}>
            <Box sx={{ border: "1px solid #ccc", padding: 1, height: "360px" }}>
              <Controller
                name="content"
                control={control}
                render={({ field }) => (
                  <ReactQuill {...field} style={{ height: "300px" }} />
                )}
              />
            </Box>
          </Grid>

          {type === "modify" && (
            <>
              <LabelBox label="등록일" />
              <ContentBox xs={4}>
                {new Date(noticeInfo?.createdAt || "").toLocaleString()}
              </ContentBox>
              <LabelBox label="등록자" />
              <ContentBox xs={4}>{noticeInfo?.createdMemberName}</ContentBox>

              <LabelBox label="최종 수정일" />
              <ContentBox xs={4}>
                {new Date(noticeInfo?.updatedAt || "").toLocaleString()}
              </ContentBox>
              <LabelBox label="최종 수정자" />
              <ContentBox xs={4}>{noticeInfo?.updatedMemberName}</ContentBox>
            </>
          )}

          {/* 취소, 등록 버튼 */}
          <Grid size={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: 1,
              }}
            >
              <Button
                variant="contained"
                style={{ background: "grey" }}
                sx={{ marginRight: 1 }}
                onClick={() => navigate("/notice-management")}
              >
                취소
              </Button>
              <Button type="submit" variant="contained" color="error">
                등록
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default NoticeForm;
