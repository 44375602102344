import { AxiosApiWrapper } from "../abstract/axios-api-wrapper.abstract";
import {
  NoticeInfoType,
  NoticeListType,
} from "../../types/boardManagementTypes";

class BoardManagementApi extends AxiosApiWrapper {
  protected endPoint!: string;
  // 공지사항 문의하기 게시판 관리

  // 공지사항 리스트, 문의하기 리스트
  public fetchBoardList(
    data: Record<string, unknown>,
    endPoint: string,
  ): Promise<NoticeListType> {
    this.endPoint = `api/v1/admin/board/${endPoint}`;
    try {
      return this.post(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  public fetchBoardInfo(data: string): Promise<NoticeInfoType> {
    this.endPoint = `api/v1/admin/board/${data}`;
    try {
      return this.get();
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  public fetchBoardDelete(data: Record<string, unknown>) {
    this.endPoint = `api/v1/admin/board`;
    try {
      return this.delete(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  public fetchBoardAdd(data: any) {
    this.endPoint = `api/v1/boards`;
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      return this.post(data, config);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  public fetchBoardUpdate(data: any, endPoint: string) {
    this.endPoint = `api/v1/admin/board/${endPoint}`;
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      return this.put(data, config);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  public fetchBoardReplyAdd(data: Record<string, unknown>) {
    this.endPoint = `api/v1/admin/board/reply`;
    try {
      return this.put(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
}

export default new BoardManagementApi();
