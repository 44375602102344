import { AxiosApiWrapper } from "../abstract/axios-api-wrapper.abstract";
import { MemberListType } from "../../types/memberManagementTypes";

class MemberManagementApi extends AxiosApiWrapper {
  protected endPoint!: string;

  // 회원관리 리스트
  public fetchMemberList(
    data: Record<string, unknown>,
    endPoint: string,
  ): Promise<MemberListType> {
    this.endPoint = `api/v1/admin/member/${endPoint}`;
    try {
      return this.post(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  public fetchMemberJoinStatusUpdate(
    data: Record<string, unknown>,
  ): Promise<MemberListType> {
    this.endPoint = `api/v1/admin/member/join-status`;
    try {
      return this.post(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
}

export default new MemberManagementApi();
