import { AppBar, Button, Tab, Tabs, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import { useRecoilState, useRecoilValue } from "recoil";
import { MRT_ColumnDef } from "material-react-table";
import Swal from "sweetalert2";
import moment from "moment";
import useMemberListQuery from "../../../hook/memberManagement/useMemberListQuery";
import MemberManagementFilter from "./MemberManagementFilter";
import CommonTable from "../../common/table/CommonTable";
import { memberManagementFilterAtom } from "../../../store/memberManagement/memberManagementFilterStore";
import { MemberType } from "../../../types/memberManagementTypes";
import { signStatusConfig, tutorParentColumns } from "../../../config/config";
import useMemberJoinStatusMutation from "../../../hook/memberManagement/useMemberJoinStatusMutation";
import { userAtom } from "../../../store/common/userStore";
import { translateValue } from "../../../util/util";
import { NoticeType, QuestionType } from "../../../types/boardManagementTypes";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const MemberManagementTabList = () => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [filterParams, setFilterParams] = useRecoilState(
    memberManagementFilterAtom,
  );
  const [endPoint, setEndPoint] = useState("list-business");
  const { data: memberList, refetch } = useMemberListQuery({
    params: filterParams,
    endPoint,
  });
  const memberJoinStatusMutation = useMemberJoinStatusMutation();
  const user = useRecoilValue(userAtom);

  const handleFilterReset = () => {
    setFilterParams({
      joinStatus: null,
      searchType: "EMAIL",
      searchText: "",
      mobileModifyStatus: null,
    });
  };

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    handleFilterReset();
    setValue(newValue);
    // 초기화가 완료된 후 탭에 따라 endPoint 설정
    if (newValue === 0) {
      setEndPoint("list-business");
    } else if (newValue === 1) {
      setEndPoint("list-tutor");
    } else if (newValue === 2) {
      setEndPoint("list-parent");
    }
  };

  const handleAccept = (memberCode: number) => {
    Swal.fire({
      text: "사업자의 대표자명과 본인인증 정보가 일치합니까? 가입 승인시 가입이 완료되며, 가입자에게 가입 완료 안내 메시지가 전송됩니다.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "예",
      cancelButtonText: "아니오",
    }).then((result) => {
      if (result.isConfirmed) {
        memberJoinStatusMutation.mutate({
          memberCode: String(memberCode),
          adminMemberCode: String(user.member.id),
          joinStatus: "ACCEPT",
        });
        console.log("승인 처리");
      }
    });
  };

  const handleReject = (memberCode: number) => {
    Swal.fire({
      // title: "거절하시겠습니까?",
      text: "사업자의 대표자명과 본인인증 정보가 일치하지 않습니까? 가입 거절 시 가입이 완료되지 않으며, 가입자에게 회원가입 불가 안내 메시지가 전송됩니다. 가입을 거절 하시겠습니까?",
      icon: "error",
      showCancelButton: true,
      confirmButtonText: "예",
      cancelButtonText: "아니오",
    }).then((result) => {
      if (result.isConfirmed) {
        memberJoinStatusMutation.mutate({
          memberCode: String(memberCode),
          adminMemberCode: String(user.member.id),
          joinStatus: "REJECT",
        });
        console.log("거절 처리");
      }
    });
  };

  const businessColumns: MRT_ColumnDef<
    MemberType | NoticeType | QuestionType,
    unknown
  >[] = [
    { accessorKey: "email", header: "아이디(이메일)" },
    { accessorKey: "bizName", header: "사업자명" },
    { accessorKey: "bizPhone", header: "사업자번호" },
    {
      accessorKey: "bizRegistrationDoc",
      header: "사업자등록증 (선택)",
      Cell: ({ row }: { row: any }) => {
        const fileUrl = row.original.bizRegistrationDoc;

        if (!fileUrl) return "-";

        const handleDownload = () => {
          // 파일명 추출
          const fileName = fileUrl.split("/").pop() || "download.pdf";

          // 파일 다운로드
          fetch(fileUrl)
            .then((response) => response.blob())
            .then((blob) => {
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement("a");
              link.href = url;
              link.download = fileName;
              document.body.appendChild(link);
              link.click();
              link.remove();
              window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
              console.error("파일 다운로드 실패:", error);
              alert("파일 다운로드에 실패했습니다.");
            });
        };

        return (
          <Button
            onClick={handleDownload}
            sx={{
              textDecoration: "underline",
              color: "primary.main",
              textTransform: "none",
              padding: 0,
              minWidth: "auto",
            }}
          >
            사업자등록증
          </Button>
        );
      },
    },
    { accessorKey: "name", header: "이름" },
    { accessorKey: "mobile", header: "휴대폰번호" },
    {
      accessorKey: "createdAt",
      header: "가입일",
      Cell: ({ row }: { row: any }) => {
        const date = row.original.createdAt;
        return date ? moment(row.original.createdAt).format("YYYY.MM.DD") : "";
      },
    },
    {
      header: "회원가입 승인상태",
      Cell: ({ row }: { row: any }) => {
        // 서버 데이터 조건에 따라 렌더링 분기 처리
        const { joinStatus } = row.original;

        return joinStatus === "REQUEST" ? (
          <>
            <Button
              color="info"
              onClick={() => {
                handleAccept(row.original.id);
              }}
              variant="outlined"
            >
              승인
            </Button>
            <Button
              style={{ marginLeft: "5px" }}
              color="info"
              onClick={() => {
                handleReject(row.original.id);
              }}
              variant="outlined"
            >
              거절
            </Button>
          </>
        ) : (
          <span>
            {translateValue(row.original.joinStatus, signStatusConfig)}
          </span>
        );
      },
    },
    {
      accessorKey: "mobileModifyStatusUpdateDate",
      header: "승인일",
      Cell: ({ row }: { row: any }) => {
        const date = row.original.joinStatusUpdateDate;
        return date
          ? moment(row.original.joinStatusUpdateDate).format("YYYY.MM.DD")
          : "";
      },
    },
    { accessorKey: "mobileModifyStatusUpdateMember", header: "승인자" },
  ];

  return (
    <Box sx={{ bgcolor: "background.paper" }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="사업자" {...a11yProps(0)} />
          <Tab label="선생님" {...a11yProps(1)} />
          <Tab label="학부모" {...a11yProps(2)} />
        </Tabs>
      </AppBar>

      <div style={{ padding: "24px" }}>
        <MemberManagementFilter
          refetch={refetch}
          type={endPoint !== "list-business" ? "tutorParent" : ""}
        />
      </div>

      <div
        style={{
          paddingLeft: "2rem",
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <h5 style={{ color: "black" }}>
          <strong>
            가입 승인 대기 :{" "}
            <span style={{ color: "#0288d1" }}> {memberList?.req_count}</span>건
          </strong>
        </h5>
      </div>

      {/* 사업자, 선생님, 학부모 탭 내부 정보 */}
      <TabPanel value={value} index={0} dir={theme.direction}>
        <CommonTable columns={businessColumns} data={memberList} />
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <CommonTable columns={tutorParentColumns} data={memberList} />
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
        <CommonTable columns={tutorParentColumns} data={memberList} />
      </TabPanel>
    </Box>
  );
};

export default MemberManagementTabList;
