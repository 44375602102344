import { atom } from "recoil";
import { NoticeListQueryParamsType } from "../../types/boardManagementTypes";

export const noticeManagementFilterAtom = atom<NoticeListQueryParamsType>({
  key: "noticeManagementFilter",
  default: {
    memberTypes: "",
    searchType: "title",
    searchText: "",
    viewFlag: null,
    topFlag: null,
  },
});
