import { useLocation } from "react-router-dom";
import useBoardInfoQuery from "../../../hook/boardManagement/useBoardInfoQuery";
import QuestionForm from "./QuestionForm";

function QuestionManagementEditFormContainer() {
  const location = useLocation();
  const { data: questionInfo } = useBoardInfoQuery({
    params: location.state,
    enabled: !!location.state, // location.state가 true일 때만 쿼리 실행
  });

  return (
    <div id="content-wrapper" className="d-flex flex-column">
      <div id="content">
        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
          <h1 className="h5 mb-0 font-weight-bold text-gray-800">
            문의하기 관리
          </h1>
        </nav>

        <div className="container-fluid">
          <QuestionForm questionInfo={questionInfo} />
        </div>
      </div>
    </div>
  );
}

export default QuestionManagementEditFormContainer;
