import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import BoardManagementApi from "../../services/boardManagement/BoardManagementApi";
import { BoardReplyAddParamsType } from "../../types/boardManagementTypes";

const useBoardReplyAddMutation = () => {
  const navigate = useNavigate();
  return useMutation(
    (params: BoardReplyAddParamsType) => {
      return BoardManagementApi.fetchBoardReplyAdd({ ...params });
    },
    {
      onSuccess: (data) => {
        navigate("/question-management");
        console.log("Mutation successful:", data);
      },
      onError: (error) => {
        console.error("Mutation error:", error);
      },
    },
  );
};

export default useBoardReplyAddMutation;
