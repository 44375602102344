import { atom } from "recoil";
import { BoardListQueryParamsType } from "../../types/boardManagementTypes";

export const questionManagementFilterAtom = atom<BoardListQueryParamsType>({
  key: "questionManagementFilter",
  default: {
    memberType: null,
    searchType: "content",
    searchText: "",
    replyFlag: null,
  },
});
