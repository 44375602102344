import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import BoardManagementApi from "../../services/boardManagement/BoardManagementApi";

const useBoardAddMutation = () => {
  const navigate = useNavigate();
  return useMutation(
    (params: FormData) => {
      return BoardManagementApi.fetchBoardAdd(params);
    },
    {
      onSuccess: (data) => {
        navigate("/notice-management");
        console.log("Mutation successful:", data);
      },
      onError: (error) => {
        console.error("Mutation error:", error);
      },
    },
  );
};

export default useBoardAddMutation;
